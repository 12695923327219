import PropTypes from "prop-types"
import React from "react"
import "../styles/layout.css"

import { makeStyles } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'

import Home from '@material-ui/icons/Home'


const useStyles = makeStyles(theme => ({
  mainBar: {
    margin: `0 auto`,
    width: `100%`,
    position: 'sticky',
  },
  toolBar: {    
    'background-color': `white`,
    'height': `7vh`,
    'min-height': `70px`
  },
  navIcon: {
    'background-color':`white`,
    'height': `100%`,
    'border-radius': `0px`,
    'padding': `1.5vw`,
  },
  navText: {
    'background-color':`white`,
    'height': `100%`,
    'border-radius': `0px`,
    'padding': `1.3vw 1.7vw 1.3vw 1.7vw`,
    [theme.breakpoints.down('sm')]: {
      'padding': `3vw`,
    }
  },
}));


function Navbar() {
  const classes = useStyles();
  return (
    <AppBar className={classes.mainBar}>
      <Toolbar variant="dense" className={classes.toolBar}>
        <IconButton href='/' edge="start" className={classes.navIcon}>
          <Home />
        </IconButton>
        <Button href='/personal/' variant="text" className={classes.navText}>
          Personal
        </Button>
        <Button href="/societal/" variant='text' className={classes.navText}>
          Societal
        </Button>
        <Button href="/financial/" variant='text' className={classes.navText}>
          Financial
        </Button>
        <div className={classes.test}>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
