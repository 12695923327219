/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from '@material-ui/core/styles';

import theme from '../styles/theme'
import NavBar from "../components/navbar"


const Layout = ({ title, header, footer, children, written, updated }) => {
  return (
    <ThemeProvider theme={theme}>
      {header}
      <NavBar />
      <div
        style={{
          margin: `0 auto`,
          marginTop: `5vh`,
          maxWidth: `900px`,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <h1>{title}</h1>
        {(written || updated)
          ? <div style={{fontFamily: 'verdana', marginLeft:`.5vw`}}>
              {written}
              {updated}
              <br/>
            </div>
          : null
        }
        <hr
          style={{
            'border-top': '1px solid grey',
            'width': '100%',
        }}/>
        <main
          style={{
            maxWidth: `800px`,
            marginLeft: `1vw`,
            fontFamily: 'verdana',
          }}
        >
          {children}
        </main>
        
        <br/><br/><br/><br/>
        <br/><br/><br/><br/>
        {footer}
        <br/><br/>
        <footer>
          <i>Site Under Construction </i>
          © {new Date().getFullYear()}
          {` JBLee.`}
        </footer>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
}

Layout.defaultProps = {
  header: null,
  footer: null,
}

export default Layout
